<template>
  <div class="emails-table">
    <v-simple-table>
      <thead>
        <tr class="emails-table__header">
          <th>Logo</th>
          <th>Name</th>
          <th>Agency</th>
          <th>Account Type</th>
          <th>Subscription Start Date</th>
          <th>Is Active</th>
          <th />
        </tr>
      </thead>
      <tbody class="emails-table__content">
        <template v-if="accounts.length > 0">
          <tr v-for="(item, i) in accounts" :key="i" style="height: 125px !important">
            <td style="max-width: 132px">
              <v-img
                v-if="item.logoUrl"
                contain
                class="my-4"
                style="cursor: pointer; max-width: 120px; max-height: 100px;padding-top: 6px"
                :src="item.logoUrl"
                @click="selectAccount(item.accountId, item.logoUrl)"
              />
              <div v-else class="account-image-placeholder">
                <div class="subheader">
                  {{ item.name }}
                </div>
              </div>
            </td>
            <td class="py-1">
              <div class="emails-table__email-header mb-1">
                {{ item.name }}
                <div class="text--disabled" style="font-size: 11px;">
                  Account ID: {{ item.accountId }}
                </div>
              </div>
            </td>
            <td class="py-1">
              {{ item.agency }}
            </td>
            <td class="py-1">
              {{ item.accountType }}
            </td>
            <td class="py-1">
              {{ getDate(item.subscriptionStartDate) }}
            </td>
            <td class="py-1">
              <v-btn
                v-if="item.isActive"
                class="text-capitalize"
                color="primary"
                text
              >
                <v-icon class="mr-0" size="16px" left>
                  mdi-check
                </v-icon>
                Active
              </v-btn>
              <v-btn
                v-else
                class="text-capitalize"
                color="gray"
                text
              >
                Inactive
              </v-btn>
            </td>

            <td class="v-menu-attach-parent">
              <v-btn
                class="elevation-0 outlined-btn px-lg-10 px-xl-15 ml-5 ml-lg-10 ml-xl-20 mb-1"
                height="34px"
                plain
                @click="selectAccount(item.accountId, item.logoUrl)"
              >
                Select
              </v-btn>
              <v-btn
                v-if="$store.getters['user/isPulseAdmin']"
                class="custom-button custom-button--blue elevation-0 outlined-btn px-lg-10 px-xl-15 ml-5 ml-lg-10 ml-xl-20 mb-1"
                height="34px"
                plain
                @click="$router.push(`/edit_account/${item.id}`)"
              >
                Edit
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <div>
                Currently you do not have any accounts.
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
name: "AccountCardList",
  mixins: [datesMixin],
  props: {
    accounts: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    async selectAccount(id, logo) {
      const selectedAccount = await this.$store.dispatch('user/selectAccount', id, logo);

      this.$notifier.success(`Successfully selected account ${selectedAccount.account.name}`)
      await this.$router.push(
          this.$route.query.next
              ? {path: this.$route.query.next}
              : {
                name: "DashboardView",
                params: {id: this.$store.getters["user/defaultDashboard"]},
                query: {
                  accid: id,
                },
              }
      );
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-image{
    background-color: rgba(216, 216, 216, 0.2);
    border-bottom: 1px solid #dee7ee;
}
.account-card {
    width: 262px;
    height: 159px;
    margin: 0 1px 0 0;
    padding: 0 0 17px;
    border-radius: 5px;
    border: solid 1px #dee7ee;
    background-color: #ffffff;
    position: relative;
}
.account-image-placeholder {
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(216, 216, 216, 0.2);
    border-bottom: 1px solid #dee7ee;
}
.edit-icon {
  pointer-events: none;
  opacity: 0;
  cursor: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 4px 4px 0 0;
  z-index: 1;
  // transition-property: opacity;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  // transition-duration: 250ms;
}
.account-card:hover .edit-icon {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}


@media (max-width: 727px) {
  .account-card {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
