<template>
  <v-card class="pb-7 pt-4 px-7">
    <v-card-text class="pa-0" style="overflow-x: hidden">
      <PageHeader>
        <template #subheader>
          Account Requests
        </template>
      </PageHeader>

      <div class="accounts-request-dialog-table">
        <v-simple-table>
          <thead>
            <tr class="campaigns-table__header">
              <th>Request Source</th>
              <th>Request Type</th>
              <th>Request By</th>
              <th>Request Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in accountsRequests"
              :key="i"
            >
              <td style="max-width: 132px">
                {{ item.requestSource }}
              </td>
              <td class="py-1">
                {{ item.requestType }}
              </td>
              <td class="py-1">
                <span v-if="item.createdBy">
                  {{ item.createdBy.firstName }} {{ item.createdBy.lastName }}
                </span>
                <span v-else>
                  {{ item.account.name }}
                </span>
              </td>
              <td class="py-1">
                {{ getDate(item.createdAt) }}
              </td>
              <td>
                <v-menu nudge-top="-5px" nudge-left="15px" :offset-y="true">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="elevation-0 outlined-btn px-12"
                      height="34px"
                      plain
                      v-bind="attrs"
                      v-on="on"
                      @click="$emit('click')"
                    >
                      Actions
                    </v-btn>
                  </template>
                  <OuterList
                    title="Actions"
                    :items="outerListItems"
                    @click="$emit('action', { event: $event, item })"
                  />
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex justify-end align-center pa-0 mx-3 mt-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import OuterList from "@/sharedComponents/OuterList";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "ChooseExistingCreativeDialog",
  components: {
    PageHeader,
    OuterList,
  },
  mixins: [datesMixin],
  props: {
    accountsRequests: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      outerListItems: [
        {
          label: "Approved",
          icon: "mdi-account",
          value: "approved",
        },
        {
          label: "Decline",
          icon: "mdi-delete-outline",
          value: "decline",
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.accounts-request-dialog-table {
  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    tr {
      height: 120px;
    }
    tr > td {
      border-bottom: solid 1px #dee7ee;
    }
    tr:hover {
      cursor: pointer;
    }
  }

  .selected-row {
    background: #e8e8e8;
    border: 1px solid #2b84eb;
  }
}
</style>
