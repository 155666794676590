import { render, staticRenderFns } from "./AccountRequestDialog.vue?vue&type=template&id=071430cc&scoped=true"
import script from "./AccountRequestDialog.vue?vue&type=script&lang=js"
export * from "./AccountRequestDialog.vue?vue&type=script&lang=js"
import style0 from "./AccountRequestDialog.vue?vue&type=style&index=0&id=071430cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071430cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VMenu,VSimpleTable})
