<template>
  <v-container class="mt-5 px-10" fluid>
    <PageHeader
      header-text="Account Selection"
      :regular-button-text="
        $store.getters['user/isPulseAdmin'] ? 'NEW ACCOUNT' : null
      "
      :outlined-button-text="
        $store.getters['user/isPulseAdmin'] && accounts_requests.length > 0 ? '(' + accounts_requests.length + ') Account Request' : null
      "
      :regular-button-visible="$store.getters['user/isPulseAdmin']"
      :outlined-button-visible="$store.getters['user/isPulseAdmin']"
      outlined-button-color="error"
      @regular-button-click="$router.push({name: 'NewAccount'})"
      @outlined-button-click="requestModal=true"
    >
      <template #subheader>
        <div v-if="$store.getters['user/user_info'].first_name.length > 0" class="mb-1">
          Hi
          <span class="name">{{ $store.getters["user/user_info"].first_name }}</span>
          , it’s nice to see you
          <img
            src="@/assets/icons/waving-hands.png"
            width="16px"
            height="16px"
          >
        </div>
        <div>Please select which account you’d like to manage</div>
      </template>
    </PageHeader>

    <v-row
      v-if="recent_accounts.length !== 0"
      class="mb-6"
    >
      <v-col>
        <recent-accounts>
          <account-card
            v-for="account in recent_accounts"
            :id="account.id"
            :key="account.id"
            :img="account.logoUrl"
            :is-active="account.isActive"
            :account-type="account.accountType"
            :name="account.name"
            @click="select_account(account.accountId, account.logoUrl)"
          />
        </recent-accounts>
      </v-col>
    </v-row>

    <template v-if="all_accounts.length > 0">
      <div class="box-head">
        All Accounts
      </div>
      <v-row>
        <v-col
          style="max-width: 380px"
          cols="12"
          sm="8"
          lg="6"
          xl="4"
          class="d-flex align-center justify-start pr-2"
        >
          <v-text-field
            v-model="search_string"
            prepend-inner-icon="mdi-magnify"
            dense
            depressed
            height="44px"
            outlined
            placeholder="Search for accounts"
            @input="onSearch"
          />
        </v-col>
        <v-checkbox
          v-model="is_active_filter"
          :false-value="null"
          class="search-field-row-checkbox mt-5"
          label="Only Show Active"
          @change="onSearch"
        />
        <v-col cols="7" class="mt-2">
          <v-btn
            v-if="viewValue === 'list'"
            class="absolute-copy-button float-right"
            icon
            small
            @click="setViewType('grid')"
          >
            <v-icon size="36" color="#66788e">
              mdi-view-grid
            </v-icon>
          </v-btn>
          <v-btn
            v-if="viewValue === 'grid'"
            class="absolute-copy-button float-right"
            icon
            small
            @click="setViewType('list')"
          >
            <v-icon size="36" color="#66788e">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <all-accounts v-if="viewValue === 'grid'">
            <account-card
              v-for="account in search_accounts"
              :id="account.id"
              :key="account.id"
              :img="account.logoUrl"
              :is-active="account.isActive"
              :account-type="account.accountType"
              :name="account.name"
              @click="select_account(account.accountId, account.logoUrl)"
            />
          </all-accounts>
          <div v-else>
            <account-card-list
              :accounts="search_accounts"
            />
          </div>
        </v-col>
      </v-row>
    </template>
    <v-dialog :value="requestModal" width="800">
      <AccountRequestDialog
        v-if="requestModal"
        :accounts-requests="accounts_requests"
        @dismiss="requestModal = false;"
        @action="handleAction($event)"
      />
    </v-dialog>
    <v-dialog v-model="newAccountDialog" scrollable max-width="560px">
      <NewAccountStatusDialog
        v-if="newAccountDialog"
        :account="newAccount"
        @update="(status) => newAccount.status = status"
        @dismiss="newAccountDialog = false"
        @failed="(errMsg) => { newAccountFailed = true; newAccount.failedMessage = errMsg; }"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import RecentAccounts from "@/views/Accounts/components/RecentAccounts";
import AccountCard from "@/views/Accounts/components/AccountCard";
import AccountCardList from "@/views/Accounts/components/AccountCardList";
import AllAccounts from "@/views/Accounts/components/AllAccounts";
import PageHeader from "@/sharedComponents/PageHeader";
import AccountRequestDialog from "@/views/Accounts/AccountRequestDialog";
import NewAccountStatusDialog from "@/views/Accounts/components/NewAccountStatusDialog";

export default {
  name: "AccountsView",
  metaInfo: {
    title: 'Accounts'
  },
  components: {
    AllAccounts,
    AccountCard,
    AccountCardList,
    RecentAccounts,
    PageHeader,
    AccountRequestDialog,
    NewAccountStatusDialog,
  },
  data() {
    return {
      is_active_filter: null,
      all_accounts: [],
      recent_accounts: [],
      search_string: "",
      all_accounts_total: 999,
      isLoaded: false,
      search_accounts: [],
      accounts_requests: [],
      requestModal: false,
      newAccountDialog: false,
      newAccount: null,
      newAccountFailed: false,
      viewValue: this.$store.getters["user/showAccountsType"],
    };
  },
  async created() {
    await this.get_recent_accounts();
    await this.getAllAccounts();
    this.isLoaded = true;
    const user_details = (await this.$rest.user.get_user_details()).data;
    this.$store.commit("user/update_user_info", {
      ...user_details,
      accountTotal: this.all_accounts_total
    });
    this.$store.commit("user/update_roles", user_details.roles);
    localStorage.setItem('user', JSON.stringify({
      ...user_details,
      accountTotal: this.all_accounts_total,
    }));
    if (this.all_accounts_total === 1 && !this.$store.getters['user/isPulseAdmin']) {
      await this.select_account(this.all_accounts[0].accountId, this.all_accounts[0].logoUrl);
    }
    if (this.$store.getters['user/isPulseAdmin']) {
      await this.getAccountRequests()
    }
  },
  methods: {
    onSearch() {
      if (this.is_active_filter) {
        this.search_accounts = this.all_accounts.filter(x => ( x.name.toLowerCase().indexOf(this.search_string.toLowerCase()) !== -1 && x.isActive === true));
      } else {
        this.search_accounts = this.all_accounts.filter(x => ( x.name.toLowerCase().indexOf(this.search_string.toLowerCase()) !== -1));
      }
    },
    async get_recent_accounts() {
      const resp = await this.$rest.account.get_collection({
        limit: 5,
        page: 1,
        lastAccessedDate: true,
        status: 'live',
      });
      this.recent_accounts = resp.data.items;
    },
    async getAccountRequests() {
      const resp = await this.$rest.account.get_account_requests_collection({
        status: 'pending',
      });
      this.accounts_requests = resp.data.items;
    },
    async getAllAccounts(searchString) {
      localStorage.removeItem("account");
      localStorage.removeItem("defaultDashboard");
      this.$store.getters["user/account"].accountId = undefined;
      this.$store.commit("user/update_selected_account", undefined);
      const resp = await this.$rest.account.get_collection({
        ignorePagination: true,
        name: searchString || this.search_string,
        isActive: this.is_active_filter ? true : null,
        sort: ['name:asc'],
        status: 'live',
      });
      this.all_accounts = resp.data.items;
      this.all_accounts_total = resp.data.totalCount;
      // <= 5 then get the unique one
      if (this.all_accounts_total <= 5 && this.all_accounts_total > 1) {
        this.all_accounts = [
          ...resp.data.items.filter((account) => {
            return !this.recent_accounts.find((recentAccount) => recentAccount.id == account.id);
             }),
        ];
      }
      this.search_accounts = this.all_accounts;
    },
    async select_account(id, logo) {
      const selectedAccount = await this.$store.dispatch('user/selectAccount', id, logo);

      this.$notifier.success(`Successfully selected account ${selectedAccount.account.name}`)
      await this.$router.push(
        this.$route.query.next
          ? { path: this.$route.query.next }
          : {
              name: "DashboardView",
              params: { id: this.$store.getters["user/defaultDashboard"] },
              query: {
                accid: id,
              },
            }
      );
    },
    async handleAction(action) {
      if (action.event.value === "decline") {
        await this.$rest.account.put_account_request(action.item.id, {status: 'canceled'});
        await this.getAccountRequests()
        this.requestModal = false;
      } else if (action.event.value === "approved") {
        const result = await this.$rest.account.approved_account_request(action.item.id);
        await this.getAccountRequests()
        await this.getAllAccounts()
        this.newAccount = result.data;
        this.requestModal = false
        this.newAccountDialog = true;
      }
    },
    setViewType(value) {
      this.$store.commit("user/updateAccountsListView", value);
      this.viewValue = value;
    }
  },
};
</script>

<style scoped lang="scss">
.mt--2 {
  margin-top: -8px;
}
::v-deep .search-field-row-checkbox {
  .v-input__control {
    .v-messages {
      display: none;
    }
    .v-label {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
    }
    .v-icon {
      font-size: 18px;
    }
  }
}

.box-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
  padding-bottom: 24px;
}

span.name {
  color: #2b84eb;
  font-weight: 600;
}
.page-header-btn .v-btn__content {
  font-size: 13px !important;
  color: #9d0f0f !important;
  border-color: #9d0f0f !important;
}
</style>
