<template>
  <div>
    <div class="d-flex flex-wrap justify-center justify-sm-start">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "AllAccounts",
};
</script>
